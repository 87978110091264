import React from 'react'
import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import _ from 'lodash'

const CHAIR_COLORS = ['black', 'cappuccino', 'fossil_grey', 'ivory', 'dark_chocolate', 'burgundy']
const TEXTURES = ['regular', 'diamond']

export default ({ model, defaultChairColor, defaultTexture, price, name, bg }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          Pedistoolcappuccino: file(relativePath: { eq: "pedi-stool/pedistool-cappuccino.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolivory: file(relativePath: { eq: "pedi-stool/pedistool-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolfossilgrey: file(relativePath: { eq: "pedi-stool/pedistool-fossilgrey.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolburgundy: file(relativePath: { eq: "pedi-stool/pedistool-burgundy.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistooldarkchocolate: file(
            relativePath: { eq: "pedi-stool/pedistool-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolblack: file(relativePath: { eq: "pedi-stool/pedistool-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolcappuccinodiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-cappuccino-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolivorydiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-ivory-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolfossilgreydiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-fossilgrey-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolburgundydiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-burgundy-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistooldarkchocolatediamond: file(
            relativePath: { eq: "pedi-stool/pedistool-darkchocolate-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Pedistoolblackdiamond: file(
            relativePath: { eq: "pedi-stool/pedistool-black-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchaircappuccino: file(relativePath: { eq: "tech-chair/techchair-cappuccino.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairivory: file(relativePath: { eq: "tech-chair/techchair-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairfossilgrey: file(relativePath: { eq: "tech-chair/techchair-fossilgrey.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairburgundy: file(relativePath: { eq: "tech-chair/techchair-burgundy.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairdarkchocolate: file(
            relativePath: { eq: "tech-chair/techchair-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairblack: file(relativePath: { eq: "tech-chair/techchair-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchaircappuccinodiamond: file(
            relativePath: { eq: "tech-chair/techchair-cappuccino-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairivorydiamond: file(
            relativePath: { eq: "tech-chair/techchair-ivory-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairfossilgreydiamond: file(
            relativePath: { eq: "tech-chair/techchair-fossilgrey-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairburgundydiamond: file(
            relativePath: { eq: "tech-chair/techchair-burgundy-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairdarkchocolatediamond: file(
            relativePath: { eq: "tech-chair/techchair-darkchocolate-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Techchairblackdiamond: file(
            relativePath: { eq: "tech-chair/techchair-black-diamond.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolcappuccino: file(relativePath: { eq: "mani-stool/manistool-cappuccino.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolivory: file(relativePath: { eq: "mani-stool/manistool-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolfossilgrey: file(relativePath: { eq: "mani-stool/manistool-fossilgrey.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolburgundy: file(relativePath: { eq: "mani-stool/manistool-burgundy.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistooldarkchocolate: file(
            relativePath: { eq: "mani-stool/manistool-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Manistoolblack: file(relativePath: { eq: "mani-stool/manistool-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchaircappuccino: file(
            relativePath: { eq: "customer-chair/customerchair-cappuccino.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 462, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairivory: file(relativePath: { eq: "customer-chair/customerchair-ivory.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 462, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairfossilgrey: file(
            relativePath: { eq: "customer-chair/customerchair-fossilgrey.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 462, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairburgundy: file(
            relativePath: { eq: "customer-chair/customerchair-burgundy.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 462, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairdarkchocolate: file(
            relativePath: { eq: "customer-chair/customerchair-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 462, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairblack: file(relativePath: { eq: "customer-chair/customerchair-black.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 462, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairsofacappuccino: file(
            relativePath: { eq: "customer-chair-sofa-style/customerchair-sofa-cappuccino.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairsofaivory: file(
            relativePath: { eq: "customer-chair-sofa-style/customerchair-sofa-ivory.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairsofafossilgrey: file(
            relativePath: { eq: "customer-chair-sofa-style/customerchair-sofa-fossilgrey.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairsofaburgundy: file(
            relativePath: { eq: "customer-chair-sofa-style/customerchair-sofa-burgundy.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairsofadarkchocolate: file(
            relativePath: { eq: "customer-chair-sofa-style/customerchair-sofa-darkchocolate.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
          Customerchairsofablack: file(
            relativePath: { eq: "customer-chair-sofa-style/customerchair-sofa-black.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={data => (
        <AccessoryChairs
          data={data}
          model={model}
          defaultChairColor={defaultChairColor}
          defaultTexture={defaultTexture}
          price={price}
          name={name}
          bg={bg}
        />
      )}
    />
  )
}

const AccessoryChairs = ({ data, model, defaultChairColor, defaultTexture, price, name, bg }) => {
  const [currentChairColor, setCurrentChairColor] = React.useState(defaultChairColor)
  const [currentTexture, setCurrentTexture] = React.useState(defaultTexture)

  const CHAIR_IMAGES = React.useMemo(() => {
    return {
      pedistool: {
        cappuccino_regular: data.Pedistoolcappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Pedistoolivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Pedistoolfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Pedistoolburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Pedistooldarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Pedistoolblack.childImageSharp.gatsbyImageData,

        cappuccino_diamond: data.Pedistoolcappuccinodiamond.childImageSharp.gatsbyImageData,
        ivory_diamond: data.Pedistoolivorydiamond.childImageSharp.gatsbyImageData,
        fossil_grey_diamond: data.Pedistoolfossilgreydiamond.childImageSharp.gatsbyImageData,
        burgundy_diamond: data.Pedistoolburgundydiamond.childImageSharp.gatsbyImageData,
        dark_chocolate_diamond: data.Pedistooldarkchocolatediamond.childImageSharp.gatsbyImageData,
        black_diamond: data.Pedistoolblackdiamond.childImageSharp.gatsbyImageData
      },
      techchair: {
        cappuccino_regular: data.Techchaircappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Techchairivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Techchairfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Techchairburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Techchairdarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Techchairblack.childImageSharp.gatsbyImageData,

        cappuccino_diamond: data.Techchaircappuccinodiamond.childImageSharp.gatsbyImageData,
        ivory_diamond: data.Techchairivorydiamond.childImageSharp.gatsbyImageData,
        fossil_grey_diamond: data.Techchairfossilgreydiamond.childImageSharp.gatsbyImageData,
        burgundy_diamond: data.Techchairburgundydiamond.childImageSharp.gatsbyImageData,
        dark_chocolate_diamond: data.Techchairdarkchocolatediamond.childImageSharp.gatsbyImageData,
        black_diamond: data.Techchairblackdiamond.childImageSharp.gatsbyImageData
      },
      manistool: {
        cappuccino_regular: data.Manistoolcappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Manistoolivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Manistoolfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Manistoolburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Manistooldarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Manistoolblack.childImageSharp.gatsbyImageData
      },
      customerchair: {
        cappuccino_regular: data.Customerchaircappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Customerchairivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Customerchairfossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Customerchairburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Customerchairdarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Customerchairblack.childImageSharp.gatsbyImageData
      },
      customerchairsofa: {
        cappuccino_regular: data.Customerchairsofacappuccino.childImageSharp.gatsbyImageData,
        ivory_regular: data.Customerchairsofaivory.childImageSharp.gatsbyImageData,
        fossil_grey_regular: data.Customerchairsofafossilgrey.childImageSharp.gatsbyImageData,
        burgundy_regular: data.Customerchairsofaburgundy.childImageSharp.gatsbyImageData,
        dark_chocolate_regular: data.Customerchairsofadarkchocolate.childImageSharp.gatsbyImageData,
        black_regular: data.Customerchairsofablack.childImageSharp.gatsbyImageData
      }
    }
  }, [data])

  return (
    <div className={classNames(['row shadow-sm py-2', bg])}>
      <div className="col-lg-6 py-4 d-flex flex-column justify-content-center align-items-center animated fadeInLeft">
        <GatsbyImage
          image={CHAIR_IMAGES[`${model}`][`${currentChairColor}_${currentTexture}`]}
          style={{
            width: '100%',
            maxWidth: '250px',
            height: 'auto'
          }}
          className="animated bounceIn slow"
          alt=""
        />
        <h6 className="text-secondary my-4">Digital Coloring May Vary</h6>
      </div>

      <div className="col-lg-6 d-flex flex-column justify-content-center animated fadeInRight slow">
        <div className="d-flex align-items-center flex-wrap">
          <h6 className="mb-0 mr-2">
            <strong>Color</strong>
          </h6>

          {CHAIR_COLORS.map(chairColor => {
            return (
              <button
                key={chairColor}
                type="button"
                aria-label="chair color button"
                onClick={() => setCurrentChairColor(chairColor)}
                className={classNames('color-btn', `${chairColor}`, 'rounded-circle mr-1 mb-1', {
                  'animated infinite pulse': currentChairColor === chairColor
                })}
              />
            )
          })}
        </div>

        {model === 'pedistool' && (
          <div className="mt-2 d-flex  align-items-center flex-wrap">
            <h6 className="mb-0 mr-2">
              <strong>Stitch</strong>
            </h6>
            {TEXTURES.map(texture => {
              return (
                <button
                  key={texture}
                  type="button"
                  className={classNames('action-button rounded-pill mx-1', {
                    'animated infinite pulse': currentTexture === texture
                  })}
                  onClick={() => setCurrentTexture(texture)}
                >
                  {texture}
                </button>
              )
            })}
          </div>
        )}

        <div className="mt-2 animated fadeInRight slow">
          <h2 className="text-uppercase my-0">
            {name}{' '}
            <p>
              {_.upperCase(currentChairColor)}
              {model === 'pedistool' && ` - ${_.upperCase(currentTexture)} STITCH`}
            </p>
          </h2>

          {model !== 'techchair' && (
            <h2 className="text-secondary my-0">
              $ {currentTexture === 'diamond' ? '255' : price}
            </h2>
          )}

          {model === 'techchair' && (
            <h2 className="text-secondary my-0">
              $ {currentTexture === 'diamond' ? '265' : price}
            </h2>
          )}

          {model === 'pedistool' && <h4>FREE with EVERY PEDICURE SPA CHAIR PURCHASE</h4>}
        </div>
      </div>
    </div>
  )
}
